///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/services/agentServices" />
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import * as remediumApi from "Core/Medius.Core.Web/Scripts/Medius/core/communication/remediumApi";
import { IMessage, IPredefinedQuestion } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import { EventSourceMessage } from "@microsoft/fetch-event-source";

export async function getMessages(){
    return new Promise<IMessage[]>(resolve => {
        setTimeout(() => {
            resolve([]);
        }, 100);
    });
}

export async function getPredefinedQuestions(): Promise<IPredefinedQuestion[]> {
    const path = "remedium-api/ap-automation/agent/predefinedQuestions";
    const response = await remediumApi.get(path);
    return await response.json();
}

export async function getPredefinedQuestionsNotFullyOperational(): Promise<IPredefinedQuestion[]> {
    const path = "remedium-api/ap-automation/agent/predefinedQuestionsNotFullyOperational";
    const response = await remediumApi.get(path);
    return await response.json();
}

export async function getLegalPredefinedQuestions(): Promise<IPredefinedQuestion[]> {
    const path = "remedium-api/ap-automation/agent/legalPredefinedQuestions";
    const response = await remediumApi.get(path);
    return await response.json();
}

export async function getPredictedNextApprovers(invoiceId: number, invoiceType: string, workflowStepName: string): Promise<string[]> {
    const path = `remedium-api/ap-automation/agent/predictedNextAuthorizers?invoiceId=${invoiceId}&invoiceType=${invoiceType}&workflowStepName=${workflowStepName}`;
    const response = await remediumApi.get(path);
    return await response.json();
}

export async function streamResponse(
    documentId: number, 
    taskId: number,
    workflowStepName: string,
    invoiceType: string,
    temporaryDelegationMessage: string,
    escalationMessage: string,
    isOperational: boolean,
    prompt: string, 
    conversationId: string,
    conversationHistory: any[],
    handleChunk: (chunk: IMessage) => void,
    handleStreamEnd: () => void,
    signal: AbortSignal,
    timeout: NodeJS.Timeout,
    disablePredefinedQuestions: boolean,
    questionId?: number,
) {
    const data = {
        DocumentId: documentId,
        TaskId: taskId,
        isOperational: isOperational,
        Prompt: prompt,
        ConversationId: conversationId,
        ConversationHistory: conversationHistory,
        QuestionId: questionId,
        WorkflowStepName: workflowStepName,
        InvoiceType: stripNamespace(invoiceType),
        TemporaryDelegationMessage: temporaryDelegationMessage,
        EscalationMessage: escalationMessage,
        DisablePredefinedQuestions: disablePredefinedQuestions
    };

    const options = {
        onmessage: (event: EventSourceMessage) => {
            const jsonChunk: IMessage = JSON.parse(event.data);
            clearTimeout(timeout);
            handleChunk(jsonChunk);
        },
        onclose: () => {
            handleStreamEnd();
        },
        onerror: (error: Error) => {
            clearTimeout(timeout);
            handleStreamEnd();
            throw error;
        },
        signal: signal
    };

    const path = "remedium-api/ap-automation/agent/chat";
    await rest.eventStreamPost(path, data, options);
}

export async function sendFeedback(
    conversationId: string, 
    documentId: number,
    feedback: string,
    workflowStepName : string,
    invoiceType: string
) {
    const data = {
        ConversationId: conversationId,
        DocumentId: documentId,
        Feedback: feedback,
        WorkflowStepName: workflowStepName,
        InvoiceType: stripNamespace(invoiceType)
    };

    const path = "remedium-api/ap-automation/agent/feedback";
    await rest.post(path, data);
}

export async function getInvoiceContext(documentId: number, isOperational: boolean): Promise<IMessage> {
    let url = "remedium-api/ap-automation/agent/invoiceContext";
    if(!isOperational)
        url+="/nonOperational";
    
    url+=`/${documentId}`;

    const response = await remediumApi.get(url);
    return await response.json();
}

function stripNamespace(valueWithNamespace: string) {
    const splitString = valueWithNamespace.split('.');
    return splitString[splitString.length - 1];
}