/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/rpc"/>
import { toAction, getUrlWithBase } from "../lib/path";
import { ajax as ajaxRawCall } from "../lib/ajax";
import { getMigratedUrlIfNeeded} from "Core/Medius.Core.Web/Scripts/Helpers/migrationApisHelper";

function createMediusFlowUrl(service: string, resource: string) {
  return `${toAction("Rpc", "~/Backend")}/${service}/${resource}`;
}

function doNotAddBase(url:string){
  return url;
}

export function ajax(
  service: string,
  resource: any,
  options: JQueryAjaxSettings = {},
  signal?: AbortSignal
) {
  const mediusFlowUri = createMediusFlowUrl(service, resource);
  const maybeMigratedUri = getMigratedUrlIfNeeded(mediusFlowUri, doNotAddBase); 

  options = { ...options, type: "POST" };
  return ajaxRawCall(maybeMigratedUri, options, signal);
}

export function ping(action: string) {
  const url = getUrlWithBase(action);
  return ajaxRawCall(url, { type: "POST" });
}
