///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/components/copyRequisition/copyPurchaseRequisitionComponent" />
import * as React from "react";
import { CopyRequisitionButton } from "Core/Medius.Core.Web/Scripts/Medius/components/components/copyRequisition/copyPurchaseRequisitionButton";
import { store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { Provider } from "react-redux";
import { Company } from "Core/Medius.Core.Web/Scripts/components/procurement/currentCompany/configuration";

interface CopyRequisitionComponentProps {
    documentId: number,
    documentIdentifier: string,
    companyId: number,
    companyName: string,
    onCopyRequisitionToBasket: () => void
}

export const CopyRequisitionComponent = ({ documentId, documentIdentifier, companyId, companyName, onCopyRequisitionToBasket }: CopyRequisitionComponentProps) => {

    const company: Company = {
        id: companyId,
        name: companyName
    };

    return (
        <Provider store={store}>
            <CopyRequisitionButton documentId={documentId} documentIdentifier={documentIdentifier} company={company} onCopyRequisitionToBasket={onCopyRequisitionToBasket} />
        </Provider>
    );
};