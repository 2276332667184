/// <amd-module name="Core/Medius.Core.Web/Scripts/components/sendEmail/sendEmailCapture/sendEmailContainerService"/>
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/remediumApi";
import * as emailValidator from "Core/Medius.Core.Web/Scripts/Medius/core/validation/email";

export const getCompanyUsers = (companyId: number): Promise<CompanyUserListItem[]> =>
    rest.get(`remedium-api/invoiceimageusers/${companyId}`)
        .then(response => response.json())
        .then(users => users.map((receiver: RestInvoiceImageReceiver) => {
            const emailOrUsername = receiver.email == "" ? receiver.userName : receiver.email;
            return new CompanyUserListItem(receiver.name, emailOrUsername, receiver.email);
        }));

export const sendEmail = (captureEmailInfo: EmailInfo | CaptureEmailInfo, recipients: string[]): Promise<any> => {
    if (captureEmailInfo as CaptureEmailInfo) {
        return rest.post("Backend/Rpc/MediaManager/SendEmailCapture", new Email(captureEmailInfo, recipients));
    }

    return rest.post("Backend/Rpc/MediaManager/SendEmail", new Email(captureEmailInfo, recipients));
};

export const validateEmail = (email: string): boolean => emailValidator.getRegex().test(email);

export class EmailInfo {
    constructor(public subject: string, public message: string, public docId: number,
        public docType: string, public hash: string, public tag: string) { }
}

export class CaptureEmailInfo {
    constructor(public subject: string, public message: string, public docId: number, public docType: string, public ccfInvoiceId: string) { }
}

export class Email {
    constructor(public emailInfo: EmailInfo | CaptureEmailInfo, public receivers: string[]) { }
}

export class CompanyUserListItem {
    constructor(public text: string, public value: string, public subText?: string) { }
}

export class Recipient {
    constructor(public name: string, public email: string) { }
}

interface RestInvoiceImageReceiver {
    id: number; userName: string; name: string; email: string
}