/* eslint-disable react-hooks/exhaustive-deps */
///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/CopilotSidebar/CopilotSidebar" />
import * as React from "react";
import * as services from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/services/agentServices";
import { EmptyState, ImageMessagessConversation } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { PredefinedQuestions } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/PredefinedQuestionButtons";
import { PromptInput } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/PromptInput";
import { MessageList } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/MessageList";
import { ILastChatRequestDetails, IMessage, Role, StreamCallScenario } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import { useCopilotContext } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/hooks/useCopilotContext";
import { whyHaveIReceivedThisInvoice, whyHaveIReceivedThisInvoiceQuestionId } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/AgentConsts";
import { TimeoutErrorMessage } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/TimeoutErrorMessage";
import { trackSentCopilotPromptEventInCandu } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/utils";

export interface ICopilotSidebarProps{
    getDelegationMessage: () => string;
    getEscalationMessage: () => string;
}

export function CopilotSidebar({ getDelegationMessage, getEscalationMessage }: ICopilotSidebarProps){
    const { 
        isOperational,
        copilotEventPayload, 
        predefinedQuestions, 
        setCopilotEventPayload,
        setMessages,
        messages,
        documentId,
        workflowStepName,
        invoiceType,
        setProcessedMessage,
        isStreamActive,
        setIsStreamActive,
        setAbortController,
        setRequestTimeout,
        isAgentAvailable,
        isLoadingMessages,
        conversationId,
        taskId,
        isDocumentImport
    } = useCopilotContext();
    const promptInputRef = React.useRef(null);
    const [latestChatCallDetails, setLatestChatCallDetails] = React.useState<ILastChatRequestDetails | null>(null); //retry mechanism details - originally nullified, when no previous operations were made
    const [isTimeoutError, setIsTimeoutError] = React.useState<boolean>(false);
    //[event api] react on event payload change (happens after event payload is received and processed)
    React.useEffect(() => {
        if(copilotEventPayload !== null && messages.length !== 0) { 
            if (copilotEventPayload.prompt) {
                sendFreetextQuestion(copilotEventPayload.prompt);
            } else if (copilotEventPayload.questionId) {
                const predefinedQuestionLabel = predefinedQuestions.filter(pq => pq.categorizationId === copilotEventPayload.questionId)[0];
                sendPredefinedQuestion(predefinedQuestionLabel.value, false);
            }
            setCopilotEventPayload(null);
        }
    }, [copilotEventPayload, messages]);

    const handleStreamEnd = () => {
        setIsStreamActive(false);
    };

    const addMessage = (content: string) => {
        setMessages(prev => [...prev, {
            isError: false,
            content: content,
            role: Role.user,
            conversationId: conversationId,
            documentId: documentId,
            workflowStepName: workflowStepName,
            invoiceType: invoiceType,
            date: new Date().toISOString(),
            sources: [],
            taskId: taskId
        }]);
    };

    const addErrorMessage = () => {
        setMessages(prev => [...prev, {
            isError: true,
            content: "",
            role: Role.assistant,
            conversationId: conversationId,
            documentId: documentId,
            workflowStepName: workflowStepName,
            invoiceType: invoiceType,
            date: new Date().toISOString(),
            sources: [],
            taskId: taskId
        }]);
    };

    const handleMessageChunk = (message: IMessage) => {
        setProcessedMessage({
            ...message,
            content: getLabelTranslation(message.content),
            role: Role.assistant,
            conversationId: conversationId,
            documentId: documentId,
            taskId: taskId
        });
    };

    const bypassBackendCallWithLocalMessage = (localMessage: string, respondingToPredefinedQuestionId?: number) => {
        setTimeout(() => {
            handleMessageChunk({
                content: localMessage,
                role: Role.user,
                conversationId: conversationId,
                documentId: documentId,
                date: new Date().toISOString(),
                sources: [],
                taskId: taskId,
                responseToPredefinedQuestionId: respondingToPredefinedQuestionId
            });
            handleStreamEnd();
        }, 500);
    };

    const getPredefinedQuestionCategorizationId = (predefinedQuestion: string) => {
        return predefinedQuestions.find(question => question.value === predefinedQuestion).categorizationId;
    };

    const sendPredefinedQuestion = async (predefinedQuestion: string, isRetry: boolean = false) => {
        if (isStreamActive)
            return;

        if(!isRetry)
            addMessage(getLabelTranslation(predefinedQuestion));

        if(predefinedQuestion === whyHaveIReceivedThisInvoice){
            const delegationMessage = getDelegationMessage();
            const escalationMessage = getEscalationMessage();
            const localMessage = delegationMessage || escalationMessage;
            if(localMessage){
                return bypassBackendCallWithLocalMessage(localMessage, whyHaveIReceivedThisInvoiceQuestionId);
            }
        }

        sendChatRequest(predefinedQuestion, conversationId, "predefined");
    };

    const sendFreetextQuestion = async (freetextQuestion: string) => {
        if (freetextQuestion.length === 0 || freetextQuestion.trim().length === 0 || isStreamActive)
            return;

        const truncatedPrompt = freetextQuestion.substring(0, 2000);
        addMessage(truncatedPrompt);
        sendChatRequest(truncatedPrompt, conversationId, "freetext");
    };

    const sendChatRequest = async (prompt: string, conversationId: string, streamCallOrigin: StreamCallScenario) => {
        setLatestChatCallDetails({
            conversationId: conversationId,
            prompt: prompt,
            scenario: streamCallOrigin
        });
        setIsStreamActive(true);
        setIsTimeoutError(false);

        const abortControllerInstance = new AbortController();
        setAbortController(abortControllerInstance);

        const timeout = setTimeout(() => {
            abortControllerInstance.abort();
            handleStreamEnd();
            setIsTimeoutError(true);
        }, 60000);
        setRequestTimeout(timeout);

        try {
            await services.streamResponse(
                documentId,
                taskId,
                workflowStepName,
                invoiceType,
                getDelegationMessage(),
                getEscalationMessage(),
                isOperational,
                streamCallOrigin === "predefined" ? getLabelTranslation(prompt) : prompt,
                conversationId,
                messages,
                handleMessageChunk,
                handleStreamEnd,
                abortControllerInstance.signal,
                timeout,
                isDocumentImport,
                streamCallOrigin === "predefined" ? getPredefinedQuestionCategorizationId(prompt) : null
            );
        } catch (error) { addErrorMessage(); }

        trackSentCopilotPromptEventInCandu(streamCallOrigin);
    };

    const handleStreamRetry = () => {
        setMessages(prev => {
            const newMessages = [...prev];
            newMessages.pop();
            return newMessages;
        });

        if(!latestChatCallDetails)
            return;

        sendChatRequest(latestChatCallDetails.prompt, latestChatCallDetails.conversationId, latestChatCallDetails.scenario);
    };

    if(!isAgentAvailable){
        return <EmptyState
            explanation={getLabelTranslation("#Core/agentUnavailableExplanation")}
            size="large"
            title={getLabelTranslation("#Core/agentUnavailableTitle")}
            image={<ImageMessagessConversation/>}
            centerInContainer={true}
            contentAlign="center"
        />;
    }

    return (
        <div className="mediusAgent__innerContainer" data-testid="mediusAgent__innerContainer">
            {isTimeoutError && <TimeoutErrorMessage/>}
            <MessageList promptInputRef={promptInputRef} retryCallback={handleStreamRetry} />
            { isLoadingMessages ? <></> : 
                <div className="mediusAgent__innerContainer__bottom" data-testid="mediusAgent__bottomContainer">
                    <PredefinedQuestions
                        predefinedQuestionsList={predefinedQuestions}
                        disabled={isStreamActive}
                        onPredefinedQuestionClick={sendPredefinedQuestion}
                    />
                    <PromptInput promptInputRef={promptInputRef} sendFreetextQuestion={sendFreetextQuestion} sendPredefinedQuestion={sendPredefinedQuestion} />
                </div>
            }
        </div>
    );
}