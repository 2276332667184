/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler"/>
/**
 *  This module works only with ES6 Promise based communication module that operates on Response type:
 *   'Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest'
 * 
 *  For handling errors from older modules, use backendErrorHandler
 * 
 */

import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as messageLogger from "../messageLogging/messageLogger";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {logEvent} from "Core/Medius.Core.Web/Scripts/lib/metricsLogging/metricLogger";
import * as consoleLogger from "../../Medius/lib/logger";

function isJson(response: Response) {
    const contentType = response.headers.get("content-type");
    return contentType && contentType.indexOf("application/json") !== -1;
}

function isReadableStream(response: Response) {
    return response.body instanceof ReadableStream;
}

function logDetailedBadRequestResponse(url: string, message: string, messageDetails: string) {
    const errorMessage = `BadRequest to ${url}: ${message} - ${messageDetails}`;                
    consoleLogger.error(messageDetails);
    messageLogger.logErrorMessage(errorMessage);
    notification.error(translate(message), translate("#Core/badRequestMessageTitle"));    
}

function handleBadRequest(response: Response) {
    const title = translate("#Core/badRequestMessageTitle");

    if (isJson(response)) {
        response.json().then(json => {
            // Message & MessageDetail are properties of native System.Web.Http.HttpError class             
            if (json && json.Message && json.MessageDetail) {
                logDetailedBadRequestResponse(response.url, json.Message, json.MessageDetail);
            } else if (json && json.message && json.messageDetail) {
                logDetailedBadRequestResponse(response.url, json.message, json.messageDetail);
            } else if (json && json.message) {
                notification.error(translate(json.message), translate("#Core/errorOccurred"));
            } else if (typeof json == 'string') {
                const description = translate(json) || translate("#Core/errorOccurred");
                notification.error(description, title);
            } else {
                notification.error(translate("#Core/errorOccurred"), title);
            }
        });
    } else if(isReadableStream(response)) {
        notification.error(translate("#Core/errorOccurred"), title);
    } else {
        response.text().then(text => {
            const description = text || translate("#Core/errorOccurred");
            notification.error(description, title);    
        }).catch(() => {
            notification.error(translate("#Core/errorOccurred"), title);
        });
    }
}

function handleForbiddenRequest() {
    const title = translate("#Core/forbiddenRequestMessageTitle");
    const description = translate("#Core/errorOccurred");
    notification.error(description, title);
}

function handleNotFound(response: Response) {
    const title = translate("#Core/httpException404");

    if (isJson(response)){
        response.json().then(responseJson => {
            const description = responseJson.message || translate("#Core/errorOccurred");
            notification.error(description, title);
        });
    }else{
        response.text().then(responseText => {
            const description = responseText || translate("#Core/errorOccurred");
            notification.error(description, title);
        });
    }
}

function handleServerError() {
    const title = translate("#Core/serverErrorMessageTitle");
    const description = translate("#Core/errorOccurred");
    logEvent("client-error", "generic-500");
    notification.error(description, title);
}

function handleNetworkError() {
    const title = translate("#Core/networkErrorMessageTitle");
    const description = translate("#Core/networkErrorMessageText");
    notification.error(description, title);
}

export function handleAPIError(response: Response): void {
    switch (response.status) {
        case 400:
            handleBadRequest(response);
            break;
        case 403:
            handleForbiddenRequest();
            break;
        case 404:
            handleNotFound(response);
            break;
        default:
            handleServerError();
    }
}

export function handleFrontendError(error: Error) {
    handleNetworkError();
    consoleLogger.error(error);
    messageLogger.logFromError(error);
}

export function handleUnknownError(error: any) {
    const type = error.constructor.name;
    const message = `Wrong error type: ${type}. This error handler works only with Promise based, fetch communication modules. For older ones, use backendErrorHandler module`;
    consoleLogger.error(message);

    // We throw new error to get stack tracke in message logger 
    throw Error(message);
}

export function handleError(error: Response | Error & { response?: Response }) {
    if (error instanceof Response) {
        handleAPIError(error);
    } else if (error.response && error.response instanceof Response) {
        handleAPIError(error.response);
    } else if (error instanceof Error) {
        handleFrontendError(error);
    } else {
        handleUnknownError(error);
    }
}
