/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/featureToggle"/>
import { isTenantManagerOnly } from "Core/Medius.Core.Web/Scripts/appConfig";
import { log } from "../lib/logger";

const featureToggles: { [index: string]: boolean } = {};

export const isInvoiceImageMailSentFromEndUserFlagName = "IsInvoiceImageMailSentFromEndUser";
export const isOrderRevisionEnabledFlagName = "OX.R124.OrderRevision.EnabledFor";
export const IsCodingSuggestionsV2Enabled = "AC.R111.TenantsWithCodingSuggestionsV2Enabled";
export const isSuggestedCodingInUIEnabledFlagName = "IsSuggestedCodingInUIEnabled";
export const IsDocumentFormatMappingEnabled = "BB.R87.EdiDocumentImport.DocumentFormatMapping";
export const HomePageEnabled = "AC.R101.HomePageEnabled";
export const TimeSpentMetricEnabled = "BB.R98.TimeSpentMetric";
export const IsActionableEmailsToggleVisible = "GB.R94.IsActionableEmailsToggleVisible";
export const IsAutoCodeInAllStepsEnabled = "SSG.R111.IsAutoCodeInAllStepsEnabled";
export const IsNewApprovalRulesConfigurationEnabled = "PAY.R111.IsNewApprovalRulesConfigurationEnabled";
export const IsNewUserAdministrationSuperTabsEnable = "SSG.R115.EnableUserAdministrationSuperTabs";
export const IsTruncateTrailingZeroesInBudgetAndPv2Enabled = "AX.R121.TruncateTrailingZeroesInBudgetAndPv2.EnabledFor";
export const IsCopyRequisitionEnabled = "AX.R122.CopyRequisition.GA.EnabledFor";
export const IsVersioningMonitoringEnabled = "SSG.R122.IsVersioningMonitoringEnabled";
export const IsDomainAuthenticationEnabled = "AX.R124.DomainAuthentication.EnabledFor";
export const IsServicesTabInRoleAdminPageEnabled = "FC.R128.ServicesTabInRoleAdminPage.EnabledFor";
export const IsCustomAdministrationUserGetEnabled = "FH.R131-1.CustomAdministrationUserGet.EnabledFor";
export const IsInboxFilterAutocompleteEnabled = "SSG.R131-2.InboxFilterAutocomplete.Enabled";
export const IsCopilotPredictedNextApproversEnabled = "SSG.R132-1.CopilotPredictedNextApproversEnabled";
export const IsInvoicingPlanApprovalEnabled = "HOLMES.R129.TenantsWithInvoicingPlanApprovalEnabled";
export const IsAddressManagementEnabled = "OX.R131.AddressManagement.EnabledFor";
export const IsCopilotNotificationWindowEnabled = "SSG.R134-1.CopilotNotificationWindowEnabled";
export const IsUIErrorMessagesLoggingToAppInsightsEnabled = "SSG.R134-2.IsUIErrorMessagesLoggingToAppInsightsEnabled";
export const IsAddressManagementExportImportEnabled = "OX.R135-1.AddressManagementExportImport.EnabledFor";
export const IsFetchApproverThroughRestApiEnabled = "SSG.R136-1.FetchApproverThroughRestApiEnabled";

export function register(name: string, value: boolean) {
    featureToggles[name] = value;
    log(`Feature toggle '${name}' set to '${value}'.`);
}

export function registerMany(toggles: { [key: string]: boolean }) {
    for (const [key, value] of Object.entries(toggles)) {
        register(key, value);
    }
}

export function getFlagValue(name: string) {
    return featureToggles[name];
}

export function isOrderRevisionEnabled() {
    return getFlagValue(isOrderRevisionEnabledFlagName);
}

export function isCodingSuggestionsV2Enabled() {
    return getFlagValue(IsCodingSuggestionsV2Enabled);
}

export function isSuggestedCodingInUIEnabled() {
    return getFlagValue(isSuggestedCodingInUIEnabledFlagName);
}

export function isDocumentFormatMappingEnabled() {
    return getFlagValue(IsDocumentFormatMappingEnabled);
}

export function isTimeSpentMetricEnabled() {
    return getFlagValue(TimeSpentMetricEnabled);
}

export function isHomePageEnabled() {
    return getFlagValue(HomePageEnabled)
        && !isTenantManagerOnly();
}

export function isActionableEmailsToggleVisible() {
    return getFlagValue(IsActionableEmailsToggleVisible);
}

export function isAutoCodeInAllStepsEnabled() {
    return getFlagValue(IsAutoCodeInAllStepsEnabled);
}

export function isNewApprovalRulesConfigurationEnabled() {
    return getFlagValue(IsNewApprovalRulesConfigurationEnabled);
}

export function isNewUserAdministrationSuperTabsEnabled() {
    return getFlagValue(IsNewUserAdministrationSuperTabsEnable);
}

export function isTruncateTrailingZeroesInBudgetAndPv2Enabled() {
    return getFlagValue(IsTruncateTrailingZeroesInBudgetAndPv2Enabled);
}

export function isCopyRequisitionEnabled() {
    return getFlagValue(IsCopyRequisitionEnabled);
}

export function isVersioningMonitoringEnabled() {
    return getFlagValue(IsVersioningMonitoringEnabled);
}

export function isDomainAuthenticationEnabled() {
    return getFlagValue(IsDomainAuthenticationEnabled);
}

export function isServicesTabInRoleAdminPageEnabled() {
    return getFlagValue(IsServicesTabInRoleAdminPageEnabled);
}

export function isCustomAdministrationUserGetEnabled() {
    return getFlagValue(IsCustomAdministrationUserGetEnabled);
}

export function isCopilotPredictedNextApproversEnabled() {
    return getFlagValue(IsCopilotPredictedNextApproversEnabled);
}

export function isInboxFilterAutocompleteEnabled() {
    return getFlagValue(IsInboxFilterAutocompleteEnabled);
}

export function isInvoicingPlanApprovalEnabled() {
    return getFlagValue(IsInvoicingPlanApprovalEnabled);
}

export function isAddressManagementEnabled() {
    return getFlagValue(IsAddressManagementEnabled);
}

export function isCopilotNotificationWindowEnabled() {
    return getFlagValue(IsCopilotNotificationWindowEnabled);
}

export function isUIErrorMessagesLoggingToAppInsightsEnabled(){
    return getFlagValue(IsUIErrorMessagesLoggingToAppInsightsEnabled);
}

export function isAddressManagementExportImportEnabled() {
    return getFlagValue(IsAddressManagementExportImportEnabled);
}

export function isFetchApproverThroughRestApiEnabled() {
    return getFlagValue(IsFetchApproverThroughRestApiEnabled);
}