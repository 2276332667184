/// <amd-module name="Core/Medius.Core.Web/Scripts/Models/userTask"/>
import { getOutboxInstance } from "Core/Medius.Core.Web/Scripts/components/outbox/outbox";
import * as _ from "underscore";
import { last, find, isUndefined } from "underscore";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { create as createLabelsHandlerFactory } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/task/labelsHandlerFactory";
import { error as notifyError, info as notifyInfo, success as notifySuccess } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { showWarningIfAnyRequestOngoing } from "Core/Medius.Core.Web/Scripts/components/taskHandling/blockingOperationHandler";
import { logScreenMode } from "Core/Medius.Core.Web/Scripts/Models/fullScreenUxLog";
import { executeAfterBlockingOperationsEnd } from "Core/Medius.Core.Web/Scripts/lib/workflowBlockingOperation";
import putOnHold from "Core/Medius.Core.Web/Scripts/Medius/apps/task/models/putOnHold";
import { InboxInstance as inbox } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/inbox";
import { create as createSendForReview } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/models/sendForReview";
import * as taskHandler from "Core/Medius.Core.Web/Scripts/Medius/apps/task/taskHandler";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import * as path from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as customTaskHandlerProvider from "Core/Medius.Core.Web/Scripts/Medius/core/customTaskHandlers/customTaskHandlersProvider";
import * as taskGroupResult from "Core/Medius.Core.Web/Scripts/Medius/apps/task/models/taskGroupResult";
import { documentReclassificationRequest } from "Core/Medius.Core.Web/Scripts/Medius/apps/document/events";
import { fireBlurOnActiveInputElement } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils";
import * as taskConfirmationModalHandler from "Core/Medius.Core.Web/Scripts/Medius/apps/task/taskConfirmationModalHandler";
import { getTypeName } from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import store = require("Core/Medius.Core.Web/Scripts/Medius/core/store/handling");
import * as ko from "knockout";
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import { formatValueWithPlaceholders } from "../Medius/lib/stringFormat";
import { trackEvent } from "../appInsights";

function outboxCheck(id: number) {
    /*
        Not sure why, but creating this computed inside UserTaskViewmodel makes it hold refenrece
        to "vm" even though this variable is never referenced in a closure. Since this computed outlives
        viewmodel (outbox.items notifies it) and outbox is never disposed, it cause massive memory leak.

        To mitigate that, this function creates intermediate computed that knows nothing about task
        viewmodel, just recieves task Id. Since task Id never changes without full viewmodel reload this
        is fine and removes the leak.
    */
    return ko.computed(() => getOutboxInstance().isInProgessOrDone(id));
}

function sortResults(availableResults: any) {
    availableResults = ko.unwrap(availableResults) || [];

    if (availableResults.length === 0) {
        return availableResults;
    }

    return _(availableResults).sortBy((result) => ko.unwrap(result.Id));
}

function ajaxError(data: any) {
    let errors: any;
    let msg = translate("#Core/internalErrorOccured");

    if (data.status === 400 || data.status === 403) {
        errors = $.parseJSON(data.responseText);
        if (errors.ValidationResults) {
            msg = _(errors.ValidationResults)
                .map((m) => {
                    const message = m.ValidationMessage.Message;
                    const parameters = m.ValidationMessage.MessageParams;

                    return formatValueWithPlaceholders(message, [parameters]);
                })
                .join("\n");
        } else if (errors.Message) {
            msg = errors.Message;
        }
    }

    notifyError(msg, translate("#Core/badRequestMessageTitle"));
}

function documentErrorsExtension(vm: any) {
    const taskId = vm.Id();
    const errorsAlertVisible = ko.observable(true).extend({ notify: 'always ' });

    vm.errorsHeader = translate('#Core/documentErrors');

    vm.hideErrors = () => {
        errorsAlertVisible(false);
    };

    vm.errors = ko.computed(() => {
        errorsAlertVisible(true);
        return getOutboxInstance().getErrorsForTask(taskId);
    }).extend({
        notify: 'always'
    });

    vm.errorsVisible = ko.computed(() => {
        errorsAlertVisible();
        return vm.errors().length > 0;
    }).extend({
        notify: 'always'
    });
    vm.shouldRecalculate = vm.errorsVisible;

    const baseDispose = vm.dispose;
    vm.dispose = () => {
        vm.errors.dispose();
        vm.errorsVisible.dispose();
        baseDispose();
    };
}

export const processingState: ko.Observable<boolean> = ko.observable(false);

export function UserTaskExtender(vm: any, data: any, viewmodelContext: any) {
    viewmodelContext = viewmodelContext || vm._ctx;
    vm._ctx = viewmodelContext;

    const taskId = vm.Id();
    let isInOutbox = outboxCheck(taskId);

    vm.requestOngoing = processingState;

    if (!vm.isStepCustomized) {
        vm.AvailableCodeResults(sortResults(vm.AvailableCodeResults));
        vm.AvailableGroupResults(sortResults(vm.AvailableGroupResults));
        vm.AvailableGoToTaskResults(sortResults(vm.AvailableGoToTaskResults));
        vm.AvailableCopyResults(sortResults(vm.AvailableCopyResults));

        vm.hasAvailableResults = ko.pureComputed(() => {
            return vm.AvailableCodeResults().length || vm.AvailableGroupResults().length ||
                vm.AvailableGoToTaskResults().length || vm.AvailableCopyResults().length;
        });

        vm.primaryResult = ko.pureComputed(() => {
            return last(vm.AvailableCodeResults()) || last(vm.AvailableGroupResults()) ||
                last(vm.AvailableGoToTaskResults()) || last(vm.AvailableCopyResults());
        });
    }

    vm.disableButtons = ko.computed(() => {
        return vm.requestOngoing() ||
            (isInOutbox && isInOutbox()) ||
            (vm.Document().IsAccountingLoaded !== undefined && !vm.Document().IsAccountingLoaded());
    });

    vm.ChoosenRedistribution = ko.observable();
    vm.redistributionHeader = translate('#Core/redistribute');

    vm.PopupConfiguration = ko.observableArray();
    vm.taskGroupResultViewModel = taskGroupResult.create('#taskGroupResultModal > div');
    vm.taskConfirmationModalHandler = taskConfirmationModalHandler.create();

    vm.sendForReview = createSendForReview(vm, customTaskHandlerProvider);

    vm.IsInReviewStep = false;

    vm.labelsHandler = ko.observable(null);

    vm.isLocal = vm.isLocal || ko.observable(false);

    vm.reclassify = (task: any, taskResult: any) => {
        rpc.request("UiDocumentManager", "EnsureCanReclassify", { taskId: task.Id(), taskResult: taskResult })
            .done(() => {
                $(window).trigger(documentReclassificationRequest, task);
            })
            .fail((result: any) => {
                handleAnyError(result, null, translate("#Core/document0hasFailed"));
            });
    };

    vm.copy = (d: any) => {
        vm.reclassify(vm, d);
    };

    vm.getLabelsHandler = () => {
        return createLabelsHandlerFactory(vm.Document().Id())
            .done((labelsHandler) => {
                vm.labelsHandler(labelsHandler);
            });
    };

    vm.getPopupConfiguration = () => {

        if (vm.AvailableGroupResults && vm.AvailableGroupResults().length > 0) {
            const documentType = vm.Document && vm.Document().$type;
            const activityContext = vm.ActivityContext && vm.ActivityContext().Id;

            vm.requestOngoing(true);
            return rpc.request("TaskPopupManager", "GetPopups", {
                documentFullName: getTypeName(documentType()),
                activityId: activityContext
            }).done((d: any) => {
                if (d !== null) {
                    vm.PopupConfiguration(d);
                }
            })
                .fail(ajaxError)
                .always(() => { vm.requestOngoing(false); });
        }
        return true;
    };

    vm.openPopup = (groupResult: any) => {
        const configuration = find(vm.PopupConfiguration(), (conf: any) => conf.GroupCode === groupResult.Code());
        const viewName = isUndefined(configuration) ? "" : configuration.ViewName;
        const viewPath = ['Display', getTypeName(vm.Document().$type()), viewName].join("/");
        vm.taskGroupResultViewModel.open(vm, groupResult, viewPath);
    };

    vm.addDistribution = (distributionItem: any) => {
        logger.log(distributionItem.Label());
    };

    vm.active = ko.computed(() => {
        if (vm.Perspective() === null || vm.Perspective().ReadOnly() === true) {
            return false;
        }
        return true;
    });

    vm.saveUnsavedComment = () => {
        if (vm.CommentsModel && vm.CommentsModel.newComment()) {
            vm.CommentsModel.addComment();
        }
    };

    vm.handleRedistribution = (ev: any) => {
        if (vm.ChoosenRedistribution() !== null) {
            const redistributeResult = viewmodelContext.create(sync.getNew("Medius.Core.Entities.Workflow.TaskResult.RedistributeTaskResult"));
            redistributeResult.RedistributeTo = [vm.ChoosenRedistribution().Id];
            vm.handleTaskHandler(redistributeResult, ev);
        }
    };

    vm.handleTaskHandler = (resultCode: any) => {
        const documentViewModel = vm.Document().EntityViewModel ? vm.Document().EntityViewModel() : vm.Document();
        if (documentViewModel.isLoading && documentViewModel.isLoading()) {
            notifyInfo(translate("#Core/performingRequestedOperations"));
            return;
        }

        if (documentViewModel.isValid && documentViewModel.errorMessage && !documentViewModel.isValid()) {
            notifyError(documentViewModel.errorMessage());
            return;
        }

        if (showWarningIfAnyRequestOngoing(vm)) {
            return;
        }

        const stepName = vm.ActivityContext().Name();

        logScreenMode(viewmodelContext, stepName);

        fireBlurOnActiveInputElement();

        if (vm.requestOngoing() || isInOutbox()) {
            return $.Deferred().reject();
        }

        if (resultCode === vm) {
            resultCode = null;
        }

        viewmodelContext.events.taskHandling.publish(resultCode, vm, resultCode);
        vm.requestOngoing(true);

        vm.saveUnsavedComment();

        const task = viewmodelContext.queue.enqueue({
            action: () => {
                let sendAction = null;
                if (resultCode && resultCode.getCustomHandler) {
                    sendAction = resultCode.getCustomHandler(vm);
                }
                return taskHandler.handleTask(vm, resultCode, sendAction);
            },
            name: "handle-task"
        });

        return task.getDeferred().always(() => {
            vm.requestOngoing(false);
        });
    };

    vm.handleSaveTaskDocument = () => {
        if (showWarningIfAnyRequestOngoing(vm)) {
            return;
        }
        executeAfterBlockingOperationsEnd(() => {
            vm.saveTaskDocument();
        });
    };

    vm.saveTaskDocument = () => {
        function saveHandler() {
            const handleAction = customTaskHandlerProvider.getHandler("Save", vm) || _.constant(rpc.request("TaskManager", "SaveTaskDocument", {
                task: vm.toDto()
            }));

            if (typeof handleAction === 'function') {
                return handleAction().done(() => {
                    notifySuccess(translate("#Core/documentHasBeenSaved"));
                    store.removeItem(vm.Id());
                    if (!customTaskHandlerProvider.hasCustomizedInboxHandling("Save", vm)) {
                        inbox.reloadCurrentTask();
                    }
                })
                    .fail(ajaxError);
            }
        }

        vm.requestOngoing(true);

        vm.saveUnsavedComment();

        const task = viewmodelContext.queue.enqueue({
            action: saveHandler,
            name: 'save-document'
        });

        task.getDeferred().always(() => {
            vm.requestOngoing(false);
        });
    };

    if (!vm.isStepCustomized) {
        vm.typeName = ko.computed(() => getTypeName(vm.$type()));
    }

    vm.actionsTemplate = "Core:templates/Task/HandleUserTask.html";

    vm.messagesTemplate = "Core:components/taskMessages/messages.html";

    vm.dashboardContextLink = ko.computed(() => {
        const link = ["#/Dashboard", "Task", vm.Id()].join("/");
        return path.getBasePath() + link;
    });

    vm.openDashboardInTaskContext = () => {

        trackEvent({
            name: "control-center-button-clicked"
        });
        
        const handleAction = 
            customTaskHandlerProvider.getHandler("ControlCenter", vm) || _.constant(window.location.href = vm.dashboardContextLink());

        if (typeof handleAction === 'function') {
            handleAction();
        }
    };

    vm.AllowSave = () => vm.Perspective() && vm.Perspective().AllowSave();

    vm.IsReadonly = () => vm.Perspective() && vm.Perspective().ReadOnly();

    vm.CurrentState = ko.computed(() => {
        switch (vm.State()) {
            case 0:
            case "Created":
                return "Created";
            case 1:
            case "Active":
                return "Active";
            case 2:
            case "Complete":
                return "Complete";
            case 3:
            case "Canceled":
                return "Canceled";
            case 4:
            case "Terminated":
                return "Terminated";
            case 5:
            case "Suspended":
                return "Suspended";
            default:
                throw new Error("Unknown task state");
        }
    });

    vm.allowSaveDocument = ko.computed(() => {
        return !vm.IsReadonly() && vm.AllowSave() && (vm.CurrentState() === "Active" || vm.CurrentState() === "Created");
    });

    vm.reload = () => {
        const id = vm.Id();

        getOutboxInstance().removeExisting(id);
        store.removeItem(id)
            .done(() => {
                inbox.reloadCurrentTask();
            })
            .fail((e: any) => {
                const translated = translate("#Core/problemOccuredWhileRemovingLocalVersionOfTask_taskId_message");
                const message = formatValueWithPlaceholders(translated, [id, e.message]);

                logger.error(message + "\n" + e.stack);
                notifyError(message);
            });
    };

    vm.getPopupConfiguration();

    vm.putOnHold = new putOnHold(vm);

    vm.disposeContext = () => viewmodelContext.dispose(true);

    vm.toDto = () => viewmodelContext.toJS(vm);

    vm.dispose = () => {
        vm.taskGroupResultViewModel.dispose();
        if (vm.primaryResult) {
            vm.primaryResult.dispose();
        }
        if (vm.hasAvailableResults) {
            vm.hasAvailableResults.dispose();
        }
        vm.allowSaveDocument.dispose();
        vm.CurrentState.dispose();
        vm.dashboardContextLink.dispose();
        if (vm.typeName) {
            vm.typeName.dispose();
        }
        vm.active.dispose();
        vm.sendForReview.dispose();
        vm.putOnHold.dispose();
        vm.disableButtons.dispose();

        /* This computed references global variable: outbox */
        isInOutbox.dispose();
        isInOutbox = null;

        vm.taskGroupResultViewModel = null;
        vm.sendForReview = null;
        vm.putOnHold = null;

        viewmodelContext = null;
        vm._ctx = null;
    };

    documentErrorsExtension(vm);
}

export function register() {
    mappings.register("Medius.Core.Entities.Workflow.UserTask, Medius.Core.Common", UserTaskExtender);
}
