///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/components/copyRequisition/Hooks/useCopyRequisition" />
import { useEffect, useState } from "react";
import { set } from "Core/Medius.Core.Web/Scripts/components/procurement/currentCompany/currentCompanySlice";
import { store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { CodingAndTaxValidationResultDto, CopyRequisitionLineDetailsDto, CopyRequisitionResultDto } from "Core/Medius.Core.Web/Scripts/Medius/components/components/copyRequisition/DTOs/copyRequisitionValidationResultDto";
import { Company, CompanyConfiguration, ProcurementConfiguration } from "Core/Medius.Core.Web/Scripts/components/procurement/currentCompany/configuration";
import { addProductAndCodingToCart, setOurReferenceRole, clearCart, addFreeTextAndCodingToCart, cart, setInvoiceAddress, setOrderTypeId, setBuyerRoleId, setGoodsReceiptByRoleId, setDeliveryAddress, setLineDeliveryAddress, setShippingMarks, setLineAdditionalInformation, setNoteToSupplier, setCodingOrTaxCleared, setCategoryCleared, setHeaderInformationChanged, setCopySource } from "Core/Medius.Core.Web/Scripts/components/procurement/cart/cartSlice";
import { newCartLineId } from "Core/Medius.Core.Web/Scripts/components/procurement/cartLineId";
import { procurementCartPersistenceStorageKey } from "Core/Medius.Core.Web/Scripts/shared/store/storageKeys";
import { getCurrent, getCurrentRole, getFullName, getId } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import { useAppDispatch, useAppSelector } from "Core/Medius.Core.Web/Scripts/shared/store/hooks";
import { del, get } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import { validatorConfigurationSelector } from "Core/Medius.Core.Web/Scripts/components/procurement/validatorConfiguration/validatorConfigurationSlice";
import { resolutionConfigurationSelector } from "Core/Medius.Core.Web/Scripts/components/procurement/resolutionConfiguration/resolutionConfigurationSlice";
import { CodingAndTax } from "Core/Medius.Core.Web/Scripts/components/procurement/cart/coding";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { utcNow } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/date";
import { releaseLockIntervalForDocument } from "Core/Medius.Core.Web/Scripts/Medius/lockManager";

export interface UseCopyRequisitionData {
    resolveValidationResults: (validationResult: CopyRequisitionResultDto, company: Company) => void
    modalVisible: boolean,
    setModalVisible: (state: boolean) => void
    lineValidationResults: CopyRequisitionLineDetailsDto[],
    isAnyLineValid: boolean,
    copyRequisitionToBasket: () => void,
    countOfCartLines: number,
    headerError: string
}

export const useCopyRequisition = (company: Company, documentId: number, documentIdentifier: string, onCopyRequisitionToBasket: () => void) => {

    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [lineValidationResults, setLineValidationResults] = useState<CopyRequisitionLineDetailsDto[]>();
    const [validationResult, setValidationResult] = useState<CopyRequisitionResultDto>(null);
    const [headerError, setHeaderError] = useState<string>("");
    const [countOfCartLines, setCountOfCartLines] = useState<number>(0);

    const dispatch = useAppDispatch();
    const validatorConfiguration = useAppSelector(validatorConfigurationSelector);
    const resolutionConfiguration = useAppSelector(resolutionConfigurationSelector);
    const cartState = useAppSelector(cart);

    useEffect(() => {
        if (validationResult != null) {
            const areAllLinesValid = !validationResult.lineDetails.some(r => r.validationErrorMessages.length > 0);

            if (validationResult.headerDetails.headerError == "" && areAllLinesValid && cartState.cartLines.length == 0) {
                onCopyRequisitionToBasket();
                copyRequisitionToBasket();
            }

            setCountOfCartLines(cartState.cartLines.length);
            setLineValidationResults(validationResult.lineDetails);
            setHeaderError(validationResult.headerDetails.headerError);
            setModalVisible(validationResult.headerDetails.headerError != "" || validationResult.lineDetails.some(r => r.validationErrorMessages.length > 0) || cartState.cartLines.length > 0);
        }

    }, [validationResult]);

    const resolveValidationResults = async (result: CopyRequisitionResultDto) => {
        setValidationResult(result);
    };

    const copyRequisitionToBasket = async () => {
        const codingAndTaxValidationResult = await get<CodingAndTaxValidationResultDto>(`pv2/requisition/${documentId}/validateCodingAndTax`);

        store.dispatch(clearCart());
        localStorage.removeItem(procurementCartPersistenceStorageKey());
        dispatch(set(company));
        dispatch(setOurReferenceRole({
            ourReferenceRoleId: getCurrentRole().Id,
            ourReferenceRoleOwnerId: getId()
        }));

        if (codingAndTaxValidationResult.codingInfo.some(ci => ci.isCodingValid === false)) {
            dispatch(setCodingOrTaxCleared(true));
        }

        if (codingAndTaxValidationResult.codingInfo.some(ci => ci.isCategoryValid === false)) {
            dispatch(setCategoryCleared(true));
        }

        const currentProcurementCompanyConfiguration = await get<ProcurementConfiguration>(`procurementCompanyConfiguration/${company.id}`);
        const currentCompanyConfiguration = await get<CompanyConfiguration>(`pv2/company/${company.id}/configuration`);

        const emptyCodingAndTax: CodingAndTax = {
            codings: [],
            taxIndicator1: null,
            taxIndicator2: null
        };

        validationResult.lineDetails
            .filter((r) => r.validationErrorMessages.length == 0)
            .forEach((r: CopyRequisitionLineDetailsDto) => {
                const lineId = newCartLineId();

                const codingAndTax = codingAndTaxValidationResult.codingInfo.find(ci => ci.lineNumber === r.lineNumber)?.codingAndTax ?? emptyCodingAndTax;
                const categoryValid = codingAndTaxValidationResult.codingInfo.find(ci => ci.lineNumber === r.lineNumber)?.isCategoryValid ?? false;

                if (r.product != null) {
                    const categoryId = categoryValid ? r.product.productDetails.categoryId : null;
                    const categoryCode = categoryValid ? r.product.productDetails.categoryCode : null;
                    const categoryDescription = categoryValid ? r.product.productDetails.categoryDescription : null;

                    dispatch(addProductAndCodingToCart({
                        product: { ...r.product.productDetails, categoryId: categoryId, categoryCode: categoryCode, categoryDescription: categoryDescription },
                        cartLineId: lineId,
                        quantity: r.product.quantity,
                        codingAndTax: codingAndTax,
                        configuration: currentCompanyConfiguration,
                        procurementConfiguration: currentProcurementCompanyConfiguration,
                        validatorConfiguration: { activeValidators: validatorConfiguration },
                        resolutionConfigurations: resolutionConfiguration
                    }));
                }

                if (r.freeTextItem != null) {
                    const categoryId = categoryValid ? r.freeTextItem.freeTextLine.categoryId : null;
                    const categoryCode = categoryValid ? r.freeTextItem.freeTextLine.categoryCode : null;
                    const categoryDescription = categoryValid ? r.freeTextItem.freeTextLine.categoryDescription : null;

                    dispatch(addFreeTextAndCodingToCart({
                        item: {...r.freeTextItem.freeTextLine, categoryId: categoryId, categoryCode: categoryCode, categoryDescription: categoryDescription},
                        quantity: r.freeTextItem.quantity,
                        codingAndTax: codingAndTax,
                        configuration: currentCompanyConfiguration,
                        freeTextFormId: r.freeTextItem.freeTextLine.freeTextFormId,
                        freeTextFormFields: r.freeTextItem.freeTextLine.freeTextFormFields,
                        resolutionConfigurations: resolutionConfiguration,
                        cartLineId: lineId,
                    }));
                }

                dispatch(setLineDeliveryAddress({ lineId: lineId, address: r.lineDeliveryAddress }));
                dispatch(setLineAdditionalInformation({ lineId: lineId, additionalInformation: r.lineAdditionalInformation }));
            });

        dispatch(setInvoiceAddress(validationResult.headerDetails.invoiceAddress));
        dispatch(setDeliveryAddress(validationResult.headerDetails.deliveryAddress));
        dispatch(setOrderTypeId(validationResult.headerDetails.orderTypeId));
        dispatch(setBuyerRoleId(validationResult.headerDetails.buyerId));
        dispatch(setGoodsReceiptByRoleId(validationResult.headerDetails.goodsReceiptedById));
        dispatch(setShippingMarks(validationResult.headerDetails.shippingMarks));
        if (validationResult?.headerDetails?.additionalInformation != null
            && validationResult.headerDetails.additionalInformation.trim().length > 0)
        {
            dispatch(setNoteToSupplier({
                note: validationResult.headerDetails.additionalInformation,
                date: new Date().toISOString(),
                userFirstLastName: getFullName(getCurrent()),
                userId: getId()
            }));
        }

        if (validationResult.headerDetails.scrubbedHeaderFields?.length > 0) {
            const notificationParameters = validationResult.headerDetails.scrubbedHeaderFields.map(t => translate(t)).join(", ");
            dispatch(setHeaderInformationChanged(notificationParameters));
        }

        dispatch(setCopySource({
            sourceDocumentIdentifier: documentIdentifier,
            copyingUserId: getId(),
            copiedTimestamp: utcNow().toDateString()
        }));

        setModalVisible(false);

        window.location.hash = "/procurement/checkout";

        releaseAllLocks();
    };


    return {
        resolveValidationResults,
        modalVisible,
        setModalVisible,
        lineValidationResults,
        copyRequisitionToBasket,
        countOfCartLines,
        headerError
    };
};

const releaseAllLocks = () => {
    releaseLockIntervalForDocument();
    del("Backend/Rest/pv2/lock/releaseall");
};