/// <amd-module name="Core/Medius.Core.Web/Scripts/Helpers/migrationApisHelper"/>
import { getEnabledMigratedWebApiEndpointsList, MigratedWebApiEndpoint } from "Core/Medius.Core.Web/Scripts/appConfig";
import { getAvailableMigratedWebApiEndpointsList, getMigrationCompletedEndpointsList } from "Core/Medius.Core.Web/Scripts/Helpers/migrationApisAvailableEndpointsList";
import { getUrlWithBase } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";

function isRemediumCall(url:string){
    return url.indexOf("remedium-api")>-1;
}

function isMigrationCompletedFor(searchedEndpoint:string): boolean{
    if(!searchedEndpoint)
        return false;
    return getMigrationCompletedEndpointsList()
        .some(migratedEndpoint=>fullyMatches(searchedEndpoint, migratedEndpoint));
}

const mediusFlowBackendUriParts = ["Backend/Rest/", "Backend/Rpc/lightApi/", "Backend/Rpc/"]

function splitByBackendPart(url:string)
{
    for(const mediusFlowBackendUriPart of mediusFlowBackendUriParts)
    {
        if (url.includes(mediusFlowBackendUriPart)) {
            return { 
                urlStart: url.split(mediusFlowBackendUriPart)[0], 
                urlEnd: url.split(mediusFlowBackendUriPart)[1]
            };
        }
    }
    return {urlStart: "", urlEnd: url};
}

export function getMigratedUrlIfNeeded(url: string, addBase: (s:string)=>string = getUrlWithBase): string {
    const { urlStart,urlEnd } = splitByBackendPart(url);

    if(isRemediumCall(urlEnd))
        return addBase(`${urlStart}${urlEnd}`);

    if(isMigrationCompletedFor(urlEnd) || isMigratedAndEnabledWebApiEndpoint(urlEnd))
        return addBase(`${urlStart}remedium-api/${urlEnd}`);


    return addBase(url);
}

export function isMigratedAndEnabledWebApiEndpoint(endpoint : string): boolean {
    if(!endpoint)
        return false;
    
    const migratedEndpointEntry = getEndpointEntryFromMigratedList(endpoint);
    return migratedEndpointEntry? true: false;
}


function fullyMatches(searchedEndpoint: string, fixedEndpoint:string):boolean{
    const searchedEndpointWithoutQueryParams = searchedEndpoint.split('?')[0];
    return new RegExp(`^${fixedEndpoint}/?$`, 'i').test(searchedEndpointWithoutQueryParams);
}



export function getEndpointEntryFromMigratedList(searchedEndpoint:string):MigratedWebApiEndpoint|undefined{
    const availableList = getAvailableMigratedWebApiEndpointsList();
    const isAvailable = availableList.some(availableEndpoint=>fullyMatches(searchedEndpoint,availableEndpoint));

    if (!isAvailable) 
        return undefined;

    const enabledList = getEnabledMigratedWebApiEndpointsList();
    return enabledList.find(enabledEndpoint=>fullyMatches(searchedEndpoint,enabledEndpoint.endpoint));
}