/// <amd-module name="Core/Medius.Core.Web/Scripts/components/taskHandling/shared/buttons/invalidateAndEditButtonBase"/>
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as ko from "knockout";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as documentEvents from "Core/Medius.Core.Web/Scripts/Medius/apps/document/events";
import { store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { Module } from "Core/Medius.Core.Web/Scripts/Medius/apps/reactSpa/app";
import { InvalidatePopupTemplate } from "Core/Medius.Core.Web/Scripts/components/taskHandling/fraudPopup/InvalidatePopupTemplate";
import { InvalidatePopupInconclusiveTemplate } from "Core/Medius.Core.Web/Scripts/components/taskHandling/fraudPopup/InvalidatePopupInconclusiveTemplate";
import { RiskFactorsForInvoice } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskFactorComponentDtos/riskFactorsForInvoiceDto";
import { getRiskDetails, canHandleRisks, getRiskStatus } from "Core/Medius.Core.Web/Scripts/components/taskHandling/fraudPopup/service";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";

export interface IInvalidateAndEditHandler {
    handleInvalidateAction(): void;
    getTask(): any;
    getUserTaskDto(): any;
}

export abstract class InvalidateAndEditButtonBase {
    protected invalidateAndEditHandler: IInvalidateAndEditHandler;

    private template: string;
    private isDisabled: ko.Observable<boolean>;
    private isInvaldiateAndEditHidden: ko.Observable<boolean>;
    protected isInvalidateModalVisible = ko.observable(false);
    private riskDetails: ko.Observable<RiskFactorsForInvoice> = ko.observable();
    private riskStatus = ko.observable();
    private showInvalidatePopup = ko.observable(false);
    private showIsInconclusiveInvalidatePopup = ko.observable(false);
    constructor(handler: IInvalidateAndEditHandler, isDisabled: ko.Observable<boolean>, isInvaldiateAndEditHidden: ko.Observable<boolean> = ko.observable(false)) {
        this.invalidateAndEditHandler = handler;
        this.isDisabled = isDisabled;
        this.isInvaldiateAndEditHidden = isInvaldiateAndEditHidden;
        this.template = "Core:components/taskHandling/shared/buttons/invalidate.html";
    }

    public InvalidatePopup = ko.pureComputed(() => {
        return ({
            functionComponent: InvalidatePopupTemplate,
            props: {
                    showPopup: this.showInvalidatePopup(),
                    onConfirm: this.invalidate,
                    onCancel: () => this.showInvalidatePopup(false),
                    riskDetails: this.riskDetails(),
                    taskId: this.invalidateAndEditHandler.getTask().Id()
                }
            });
        });

    public InvalidatePopupInconclusive = ko.pureComputed(() => {
        return ({
            functionComponent: InvalidatePopupInconclusiveTemplate,
            props: {
                    showPopup: this.showIsInconclusiveInvalidatePopup(),
                    onConfirm: () => {
                        if(this.riskStatus()?.hasAnyRiskFactors) {
                            this.showIsInconclusiveInvalidatePopup(false);
                            this.showInvalidatePopup(true);
                        }
                        else {
                            this.showIsInconclusiveInvalidatePopup(false);
                            this.invalidate();
                        }
                    },
                    onCancel: () => this.showIsInconclusiveInvalidatePopup(false),
                }
            });
        });

    public handleInvalidateButton = () => {
        canUserSeePopup(this.invalidateAndEditHandler.getTask().Id())
            .then(canDisplayPopup => {
                if(!canDisplayPopup) {
                    this.invalidate();
                    return;
                }
                this.handlePopups();
            })
            .catch(error => {
                handleError(error);
            });
    };

    private handlePopups = () => {
        this.isInvalidateModalVisible(false);
        this.updateRiskStatus()
        .then(() => {
            if(this.riskStatus()?.hasAnyRiskFactors) {
                this.handlePopupsWhenInvoiceHasRiskFactors();
            }
            else {
                this.handlePopupsWhenInvoiceHasNoRiskFactors();
            }
        })
        .catch((error:any) => {
            handleError(error);
        });
    };
    
    private handlePopupsWhenInvoiceHasRiskFactors = () => {
        this.updateRiskDetails()
        .then(() => {
            if(this.riskStatus()?.isInconclusive) {
                this.showIsInconclusiveInvalidatePopup(true);
                return;
            }
    
            this.showInvalidatePopup(true);
        })
        .catch((error:any) => {
            handleError(error);
        });
    };
    
    private handlePopupsWhenInvoiceHasNoRiskFactors = () => {
        if(this.riskStatus()?.isInconclusive) {
            this.showIsInconclusiveInvalidatePopup(true);
            return;
        }
        this.invalidate();
    };
     
    private updateRiskDetails = async() => {
        try {
            const riskDetails = (await getRiskDetails(this.invalidateAndEditHandler.getTask().Id())).invoiceDetails;
            this.riskDetails(riskDetails);
        }
        catch(error) {
            handleError(error);
        }
    };

    private updateRiskStatus = async () => {
        try {
            const riskStatus = await getRiskStatus(this.invalidateAndEditHandler.getTask().Id());
            this.riskStatus(riskStatus);
        }
        catch(error) {
            handleError(error);
        }
    };

    public invalidate = () => {
        this.invalidateAndEditHandler.handleInvalidateAction();
        this.showInvalidatePopup(false);
        this.isInvalidateModalVisible(false);
    };

    abstract invalidateAndEdit(): void;

    public showInvalidateModal() {
        this.isInvalidateModalVisible(true);
    }

    public hideInvalidateModal() {
        this.isInvalidateModalVisible(false);
    }

    protected emitReclassificationEvent(task: any) {
        $(window).trigger(documentEvents.documentReclassificationRequest, task);
    }

    private handleReclassificationError(error: backendErrorHandler.XHR, task: any) {
        const title = globalization.getLabelTranslation("#Core/document0hasFailed", task.Document().InvoiceNumber());
        backendErrorHandler.handleAnyError(error, null, title);
    }

    protected reclassify(task: any, taskResult: any) {
        rpc.request("UiDocumentManager", "EnsureCanReclassify", { taskId: task.Id(), taskResult })
            .done(() => this.emitReclassificationEvent(task))
            .fail((error: backendErrorHandler.XHR) => this.handleReclassificationError(error, task));
    }

    protected reclassifyEndTask(task: any) {
        rpc.request("UiDocumentManager", "EnsureCanReclassifyEndTask", { taskId: task.Id() })
            .done(() => this.emitReclassificationEvent(task))
            .fail((error: backendErrorHandler.XHR) => this.handleReclassificationError(error, task));
    }
}

function canUserSeePopup(taskId:number) {
    const isFraudAndRiskProtectionEnabled = store.getState().accesses.enabledModules.includes(Module.FraudAndRiskProtection);
    if(!isFraudAndRiskProtectionEnabled) {
        return Promise.resolve(false);
    }
    return canHandleRisks(taskId)
        .then(_ => {
            return true;
        })
        .catch(error => {
            handleError(error);
        });
}